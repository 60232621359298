import React, { useState } from 'react';
import { Tab, Nav, Row, Col, ListGroup } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { DefaultBtn, Slides } from '../../components';
import { formatToPoints } from '../../services/utils';
import RenderCkeditor from './RenderCkeditor';
import baseColors from '../../assets/stylesheets/base_colors';
import './styles.scss';

const ChallengeInfo = props => {
  const { challenge } = props;
  const [collapsed, setCollapsed] = useState(true);
  const {
    settings: { nameWord, hideWinners }
  } = useSelector(state => state.utils);

  const listWinners = () => {
    return challenge.winners.map(winner => {
      return <ListGroup.Item>{winner.fullName}</ListGroup.Item>;
    });
  };

  return (
    <>
      {challenge.imageUrl && (
        <Row>
          <Col md={{ span: 7 }} xs={{ span: 12 }}>
            <Row className="mb-3">
              <Col>
                <Slides
                  items={[{ fileInfo: { fileUrl: challenge.imageUrl } }]}
                  carouselStyle={{
                    maxHeight: 300
                  }}
                />
              </Col>
            </Row>
          </Col>

          <Col className="mb-4" md={{ order: 2 }} xs={{ span: 12, order: 1 }}>
            <h2>{challenge.title}</h2>
            <p>{challenge.summary}</p>
            <div className="d-flex flex-wrap">
              <div className="mr-2 my-auto nn-circle" />
              <p
                className="my-auto reward-text"
                style={{ color: baseColors.colorTextPointsProducts }}
              >
                {formatToPoints(challenge.reward)} {nameWord}
              </p>
            </div>
            {hideWinners === 1 ? (
              <>
                <p className="mt-3">
                  La fecha límite para realizar el desafío es:
                </p>
                <DefaultBtn className="type-btn">
                  {challenge.dateText
                    ? challenge.dateText
                    : challenge.endDate.split('00:00')[0]}
                </DefaultBtn>
              </>
            ) : (
              <>
                <p className="mt-3">Ha sido completado por:</p>
                <DefaultBtn onClick={() => setCollapsed(!collapsed)}>
                  {challenge.winners.length} personas
                </DefaultBtn>
              </>
            )}
            <ListGroup
              className={`${collapsed ? 'nn-collapsed' : ''}`}
              variant="flush"
            >
              {listWinners()}
            </ListGroup>
          </Col>
        </Row>
      )}
      <div className="challange-info-container-tab">
        <Tab.Container
          className="tab-container"
          defaultActiveKey="tab-specifications"
        >
          <Nav variant="tabs">
            <Nav.Item className="tab-label">
              <Nav.Link eventKey="tab-specifications">Descripción</Nav.Link>
            </Nav.Item>
            <Nav.Item className="tab-label">
              <Nav.Link eventKey="tab-dispatch">
                Términos y Condiciones
              </Nav.Link>
            </Nav.Item>
          </Nav>

          <Tab.Content>
            <Tab.Pane eventKey="tab-specifications">
              <RenderCkeditor
                className="mt-2 tab-content"
                text={challenge.description}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="tab-dispatch">
              <RenderCkeditor
                className="mt-2 tab-content"
                text={challenge.termsAndConditions}
              />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </>
  );
};

export default ChallengeInfo;
