import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import camelCaseRecursive from 'camelcase-keys-recursive';

import {
  indexProductsRequest,
  indexProductCategoriesRequest
} from '../../requests/products';
import { BasicDropdown, Slides } from '../../components';
import { useDebounce } from '../../services/utils';
import { indexSlidesRequest } from '../../requests/slides';
import ProductsList from './ProductsList';
import baseColors from '../../assets/stylesheets/base_colors';

const ProductIndex = () => {
  const {
    settings: { menuType, nameWord }
  } = useSelector(state => state.utils);

  const sortColumns = [
    {
      key: 1,
      title: `Más relevantes`,
      sortColumn: 'relevance_level',
      sortDirection: 'asc'
    },
    {
      key: 2,
      title: `Más recomendados`,
      sortColumn: 'recommendation_level',
      sortDirection: 'asc'
    },
    {
      key: 3,
      title: `${nameWord}: ascendente`,
      sortColumn: 'price',
      sortDirection: 'asc'
    },
    {
      key: 4,
      title: `${nameWord}: descendente`,
      sortColumn: 'price',
      sortDirection: 'desc'
    },
    {
      key: 5,
      title: 'Nombre: ascendente',
      sortColumn: 'name',
      sortDirection: 'asc'
    },
    {
      key: 6,
      title: 'Nombre: descendente',
      sortColumn: 'name',
      sortDirection: 'desc'
    }
  ];

  const [products, setProducts] = useState([]);
  const [slides, setSlides] = useState([]);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingCategory, setLoadingCategory] = useState(true);
  const [searchString, setSearchString] = useState('');
  const debouncedSearchString = useDebounce(searchString, 500);
  const [sortBy, setSortBy] = useState(sortColumns[0]);
  const dispatch = useDispatch();

  useEffect(() => {
    indexSlidesRequest({
      dispatch,
      params: {
        actives: true,
        sort_direction: 'asc',
        zone: 2
      },
      successCallback: result => {
        setSlides(camelCaseRecursive(result.data.data));
      }
    });

    indexProductCategoriesRequest({
      dispatch,
      params: {
        sort_column: 'name'
      },
      successCallback: result => {
        setCategories(camelCaseRecursive(result.data.data));
      }
    });
  }, [dispatch]);

  useEffect(() => {
    const params = {
      actives: true,
      sort_direction: sortBy.sortDirection,
      sort_column: sortBy.sortColumn
    };
    if (category) params.product_categories = category;
    if (debouncedSearchString) params.query = debouncedSearchString;
    setLoading(true);

    indexProductsRequest({
      dispatch,
      params,
      successCallback: result => {
        setLoading(false);
        setProducts(camelCaseRecursive(result.data.data));
        setLoadingCategory(false);
      }
    });
  }, [dispatch, sortBy, category, debouncedSearchString]);

  const handleOnChangeSelect = selected => {
    if (loading) return;
    if (category.find(cId => cId === selected)) {
      const newCategories = category.filter(cId => cId !== selected);
      setCategory(newCategories);
    } else {
      setCategory(prev => [...prev, selected]);
    }
  };

  const stylesOptionsSelect = {
    control: styles => {
      return {
        ...styles,
        borderRadius: 15,
        backgroundColor: baseColors.bgSelect,
        boxShadow: '0 0 0 0px #6c757d',
        borderColor: baseColors.gray600,
        ':active': {
          borderRadius: 15,
          borderColor: baseColors.gray600,
          boxShadow: '0 0 0 1px #6c757d'
        },
        '&:hover': {
          borderColor: baseColors.gray600,
          boxShadow: '0 0 0 1px #6c757d'
        },
        ':focus': {
          borderColor: baseColors.gray600,
          boxShadow: '0 0 0 1px #6c757d'
        }
      };
    },
    multiValue: styles => {
      return {
        ...styles,
        backgroundColor: baseColors.bgMultiValueSelect,
        color: baseColors.colorMultiValueSelect,
        fontWeight: 'bold',
        borderRadius: 15,
        paddingInline: 10
      };
    },
    multiValueLabel: styles => ({
      ...styles,
      color: baseColors.colorMultiValueSelect
    }),
    multiValueRemove: styles => ({
      ...styles,
      ':hover': {
        backgroundColor: baseColors.hoverBgMultiValueRemoveSelect,
        color: baseColors.hoverTextMultiValueRemoveSelect
      }
    }),
    placeholder: styles => ({
      ...styles,
      fontSize: 14,
      color: '#bdbbbb'
    }),
    menuList: styles => ({
      ...styles,
      fontSize: 14,
      color: baseColors.gray600,
      fontWeight: 'bold'
    })
  };

  return (
    <>
      <Slides items={slides} />
      <Row>
        <Col md={12} className="d-flex justify-content-center">
          <div className="container-categories mt-2">
            <div className="box-categories">
              {categories.map(c => (
                <div
                  key={c.id}
                  className={`container-btn ${
                    loading && !loadingCategory ? 'disabled' : ''
                  } ${loadingCategory ? 'skeleton-container' : ''}`}
                >
                  <button
                    type="button"
                    className={`btn-category ${
                      category.includes(c.id) ? 'active-category' : ''
                    }`}
                    onClick={() => handleOnChangeSelect(c.id)}
                  >
                    {Object.entries(c.categoryImage).length === 0 ? (
                      <div
                        style={{ backgroundColor: c.color }}
                        className="box-color-category"
                      >
                        <p>{c.name.split('')[0]}</p>
                      </div>
                    ) : (
                      <img
                        className="img-category"
                        src={c.categoryImage.fileUrl}
                        alt={`Categoria ${c.name}`}
                      />
                    )}
                  </button>
                  <p className="text-center">{c.name}</p>
                </div>
              ))}
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mt-md-3">
        <Col xs={12} sm={8} className="product-multiselect-container">
          {/* <MultiSelect
            options={categories}
            handleOnChange={handleOnChangeSelect}
            placeholder="CATEGORÍAS"
            styles={stylesOptionsSelect}
          /> */}
          <h2 id="nn-slides-title" className="screen-title">
            {nameWord || 'Premios'}
          </h2>
        </Col>
        <Col xs={12} sm={4} className="product-order-container">
          <BasicDropdown
            variant="outline-gray"
            active={sortBy.key}
            onChange={setSortBy}
            items={sortColumns}
            titleDrop="ORDENAR POR"
            containerStyle={{
              textAlign: 'end'
            }}
            buttonStyle={{
              width: '100%',
              borderRadius: 15
            }}
          />
        </Col>
      </Row>
      <div className={menuType === 1 ? 'margin-card-products' : ''}>
        <ProductsList products={products} loading={loading} />
      </div>
    </>
  );
};

export default withRouter(connect()(ProductIndex));
