import React from 'react';
import { Switch } from 'react-router-dom';
import { LoggedLayout, NonLoggedLayout, PublicLayout } from './layouts';
import {
  BenefitIndex,
  BenefitShow,
  CategoryBenefitIndex,
  ChallengeIndex,
  ChallengeShow,
  ChangePassword,
  Components,
  ContestIndex,
  Home,
  HomeContact,
  Login,
  NewsIndex,
  PrizeIndex,
  NewsShow,
  NotificationIndex,
  NotificationShow,
  Page,
  PointsIndex,
  ProductIndex,
  ProductShow,
  Profile,
  ProfileEdit,
  Ranking,
  RecoverPassword,
  ResetPassword,
  TriviaIndex
} from '../screens';

const routes = (
  <Switch>
    <LoggedLayout exact path="/home" component={Home} />
    <LoggedLayout exact path="/products" component={ProductIndex} />
    <LoggedLayout exact path="/products/:id" component={ProductShow} />
    <LoggedLayout exact path="/profile" component={Profile} />
    <LoggedLayout exact path="/profile/edit" component={ProfileEdit} />
    <LoggedLayout exact path="/challenges" component={ChallengeIndex} />
    <LoggedLayout exact path="/challenges/:id" component={ChallengeShow} />
    <LoggedLayout exact path="/trivia" component={TriviaIndex} />
    <LoggedLayout exact path="/trivia/:id" component={ChallengeShow} />
    <LoggedLayout exact path="/contest" component={ContestIndex} />
    <LoggedLayout exact path="/contest/:id" component={ChallengeShow} />
    <LoggedLayout exact path="/news" component={NewsIndex} />
    <LoggedLayout exact path="/news/:id" component={NewsShow} />
    <LoggedLayout exact path="/category_benefits" component={CategoryBenefitIndex} />
    <LoggedLayout exact path="/category/:id" component={BenefitIndex} />
    <LoggedLayout exact path="/category/:id/benefits/:id" component={BenefitShow} />
    <LoggedLayout exact path="/prize" component={PrizeIndex} />
    <LoggedLayout exact path="/prize/:id" component={NewsShow} />
    <LoggedLayout exact path="/notifications" component={NotificationIndex} />
    <LoggedLayout exact path="/change_password" component={ChangePassword} />
    <LoggedLayout exact path="/ranking" component={Ranking} />
    <LoggedLayout
      exact
      path="/notifications/:id"
      component={NotificationShow}
    />
    <LoggedLayout exact path="/points-summary" component={PointsIndex} />
    <NonLoggedLayout exact path="/login" component={Login} />
    <NonLoggedLayout exact path="/" component={Login} />
    <NonLoggedLayout exact path="/reset_password" component={ResetPassword} />
    <NonLoggedLayout
      exact
      path="/recover_password"
      component={RecoverPassword}
    />
    <PublicLayout exact path="/contact" component={HomeContact} />
    <PublicLayout exact path="/components" component={Components} />
    <PublicLayout path="/:page" component={Page} />
  </Switch>
);

export default routes;
