import React, { useEffect } from 'react';
import { Badge, Navbar } from 'react-bootstrap';
import { Menu, MenuItem } from 'react-pro-sidebar';
import { BsBellFill } from 'react-icons/bs';
import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { indexCategoryBenefitsRequest } from '../requests/categoryBenefits';
import MainLogo from '../components/Utils/MainLogo';

const MenuLinks = ({
  unreadChallenges,
  unreadNews,
  navbar,
  handleToggleSidebar
}) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const {
    settings: {
      showTriviasLink,
      showContestsLink,
      hideProductsLink,
      showPrizeLink,
      hideProfile,
      termsLink,
      showBenefits,
      prizeNameParam,
      hideRankings,
      benefitNameParam,
      hideNewsLink,
      freeLink,
      freeLinkName,
      newsTitle,
      redirectToProfile,
      challengeWord
    }
  } = useSelector(state => state.utils);

  const handleIndexCategoryRequest = () => {
    indexCategoryBenefitsRequest({
      dispatch,
      params: {
        order_by_position: 'asc'
      },
      successCallback: result => {
        localStorage.setItem(
          'categoryBenefits',
          JSON.stringify(camelCaseRecursive(result.data.data))
        );
      }
    });
  };

  useEffect(() => {
    handleIndexCategoryRequest();
  }, [pathname]);

  return (
    <Menu iconShape="square" onClick={handleToggleSidebar}>
      <Navbar.Brand className="brand-sidebar">
        <MainLogo header className="nav-logo ml-2" />
      </Navbar.Brand>
      {redirectToProfile === 1 ? (
        <>
          <MenuItem active={pathname.includes('/profile')}>
            Perfil
            <Link to="/profile" />
          </MenuItem>
          <MenuItem active={pathname.includes('/products')}>
            {prizeNameParam || 'Premios'}
            <Link to="/products" />
          </MenuItem>
          <MenuItem
            active={pathname.includes('/challenges')}
            suffix={
              unreadChallenges > 0 && (
                <>
                  <BsBellFill style={{ color: '#ffffff' }} />
                  <Badge variant="danger">{unreadChallenges}</Badge>
                </>
              )
            }
          >
            {challengeWord || 'Desafíos'}
            <Link to="/challenges" />
          </MenuItem>
          <MenuItem
            active={pathname.includes('/news')}
            suffix={
              unreadNews > 0 && (
                <>
                  <BsBellFill style={{ color: '#ffffff' }} />
                  <Badge variant="danger">{unreadNews}</Badge>
                </>
              )
            }
          >
            {newsTitle || 'Noticias'}
            <Link to="/news" />
          </MenuItem>
        </>
      ) : (
        <>
          <MenuItem active={pathname.includes('/home')}>
            Inicio
            <Link to="/home" />
          </MenuItem>
          <MenuItem
            active={pathname.includes('/challenges')}
            suffix={
              unreadChallenges > 0 && (
                <>
                  <BsBellFill style={{ color: '#ffffff' }} />
                  <Badge variant="danger">{unreadChallenges}</Badge>
                </>
              )
            }
          >
            {challengeWord || 'Desafíos'}
            <Link to="/challenges" />
          </MenuItem>
          {showTriviasLink === 1 && (
            <MenuItem active={pathname.includes('/trivia')}>
              Trivias
              <Link to="/trivia" />
            </MenuItem>
          )}
          {showContestsLink === 1 && (
            <MenuItem active={pathname.includes('/contest')}>
              {navbar ? (
                'Concursos Relámpagos'
              ) : (
                <>
                  Concursos <br /> Relámpagos
                </>
              )}
              <Link to="/contest" />
            </MenuItem>
          )}
          {hideProductsLink === 1 ? null : (
            <MenuItem active={pathname.includes('/products')}>
              {prizeNameParam || 'Premios'}
              <Link to="/products" />
            </MenuItem>
          )}
          {showBenefits === 1 &&
            JSON.parse(localStorage.getItem('categoryBenefits'))?.map(
              category => {
                return (
                  <MenuItem
                    key={category.id}
                    style={{ display: category.hasBenefits ? 'block' : 'none' }}
                    active={pathname.includes(`/category/${category.id}`)}
                  >
                    {category.name || benefitNameParam || 'Beneficios'}
                    <Link
                      to={{
                        pathname: `/category/${category.id}`,
                        state: [category.id, category.name, category.lede]
                      }}
                    />
                  </MenuItem>
                );
              }
            )}
          {hideNewsLink === 1 ? null : (
            <MenuItem
              active={pathname.includes('/news')}
              suffix={
                unreadNews > 0 && (
                  <>
                    <BsBellFill style={{ color: '#ffffff' }} />
                    <Badge variant="danger">{unreadNews}</Badge>
                  </>
                )
              }
            >
              {newsTitle || 'Noticias'}
              <Link to="/news" />
            </MenuItem>
          )}
          {showPrizeLink === 1 && (
            <MenuItem active={pathname.includes('/prize')}>
              Premios
              <Link to="/prize" />
            </MenuItem>
          )}
          {hideRankings === 1 ? null : (
            <MenuItem active={pathname.includes('/ranking')}>
              Ranking
              <Link to="/ranking" />
            </MenuItem>
          )}
          {freeLink && freeLinkName && (
            <MenuItem active={pathname.includes(freeLink)}>
              {freeLinkName}
              <Link to={`/${freeLink}`} />
            </MenuItem>
          )}
          {termsLink && (
            <MenuItem active={pathname.includes(termsLink)}>
              Bases
              <Link to={`/${termsLink}`} />
            </MenuItem>
          )}
          {hideProfile === 1 ? null : (
            <MenuItem active={pathname.includes('/profile')}>
              Perfil
              <Link to="/profile" />
            </MenuItem>
          )}
        </>
      )}
    </Menu>
  );
};

export default MenuLinks;
