/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { RibbonContainer, RightLargeRibbon } from 'react-ribbons';

const BasicCard = ({
  titleBtn,
  title,
  src,
  direction,
  text,
  variantBtn,
  subtitle,
  path,
  colorText,
  colorCard,
  colorBorder,
  withRibbon,
  ribbonText,
  className
}) => (
  <Card
    text={colorText}
    style={{ backgroundColor: colorCard }}
    border={colorBorder}
    className={`h-100 ${className}`}
  >
    {withRibbon ? (
      <RibbonContainer className="custom-class">
        <RightLargeRibbon
          backgroundColor="#014599"
          color="#f0f0f0"
          fontFamily="Arial"
        >
          {ribbonText}
        </RightLargeRibbon>
        {src ? (
          <Card.Img variant={direction} src={src} />
        ) : (
          <Card.Img variant={direction} />
        )}
      </RibbonContainer>
    ) : src ? (
      <Card.Img variant={direction} src={src} />
    ) : (
      <Card.Img variant={direction} />
    )}
    <Card.Body className="basic-card-body">
      <p>{title}</p>
      {subtitle ? (
        <Card.Subtitle className="mb-2 text-muted">{subtitle}</Card.Subtitle>
      ) : (
        false
      )}
      <div className="mt-auto">
        <h4 className="font-w-b">{text}</h4>
        {titleBtn ? (
          <Button
            as={Link}
            variant={variantBtn}
            to={path}
            className="nn-product-link"
          >
            {titleBtn}
          </Button>
        ) : (
          false
        )}
      </div>
    </Card.Body>
  </Card>
);

BasicCard.propTypes = {
  titleBtn: PropTypes.string,
  title: PropTypes.string.isRequired,
  src: PropTypes.string,
  direction: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  variantBtn: PropTypes.string,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  path: PropTypes.string,
  colorText: PropTypes.string,
  colorCard: PropTypes.string,
  colorBorder: PropTypes.string,
  withRibbon: PropTypes.bool,
  ribbonText: PropTypes.string
};

BasicCard.defaultProps = {
  direction: 'top',
  variantBtn: 'info',
  path: null,
  colorText: 'black',
  colorCard: 'light',
  colorBorder: null,
  subtitle: null,
  titleBtn: null,
  src: null,
  withRibbon: false,
  ribbonText: null
};

export default BasicCard;
