import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { BackBtn, Loader } from '../../components';
import ChallengeInfo from './ChallengeInfo';
import { showChallengeRequest } from '../../requests/challenge';

const ChallengeShow = props => {
  const location = useLocation();
  const [onRequest, setOnRequest] = useState(true);
  const [challenge, setChallenge] = useState({
    id: '',
    title: '',
    summary: '',
    description: 0,
    termsAndConditions: '',
    startDate: '',
    endDate: '',
    reward: 0
  });

  const {
    settings: { menuType }
  } = useSelector(state => state.utils);

  const redirectRoute = () => {
    const route = location.pathname.split('=')[1];
    if (!route) return '/challenges';
    if (route === 'trivia') return '/trivia';
    if (route === 'contest') return '/contest';
  };

  const handleSuccessShow = response => {
    const _challenge = camelCaseRecursive(response.data);
    setChallenge(_challenge);
    setOnRequest(false);
  };

  useEffect(() => {
    const { match, dispatch } = props;
    const challengeId = match.params.id;
    setOnRequest(true);
    showChallengeRequest(challengeId, {
      dispatch,
      successCallback: response => handleSuccessShow(response)
    });
  }, [props]);

  return onRequest ? (
    <Loader />
  ) : (
    <>
      <Row className="justify-content-center">
        <Col md={menuType === 1 ? 10 : 12}>
          <BackBtn route={redirectRoute()} className="mb-2 ml-n3" />
          <ChallengeInfo challenge={challenge} />
        </Col>
      </Row>
    </>
  );
};

export default connect()(ChallengeShow);
