import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { Col, Row, Card, Image, Table, Spinner, Badge } from 'react-bootstrap';
import moment from 'moment';
import 'moment/locale/es';
import _ from 'lodash';
import { indexSlidesRequest } from '../../requests/slides';
import { Slides, LinkBtn, Loader } from '../../components';
import showMeRequest from '../../requests/auth';
import {
  indexKpiUsersRequest,
  summaryKpiUsersRequest
} from '../../requests/kpiUsers';
import RankingChart from '../Ranking/RankingChart';
import defaultImg from '../../assets/images/default.png';
import base from '../../assets/stylesheets/base_colors';
import './styles.scss';

const Home = () => {
  const [slides, setSlides] = useState([]);
  const [slidesDashboard, setSlidesDashboard] = useState([]);
  const [slidesDashboard2, setSlidesDashboard2] = useState([]);
  const [user, setUser] = useState({ avatar: [] });
  const [kpiUsers, setKpiUsers] = useState([]);
  const [kpiSummary, setkpiSummary] = useState([]);
  const [myRank, setMyRank] = useState('0/0');
  const [loading, setLoading] = useState(false);
  const [loadingChart, setLoadingChart] = useState(false);
  const [loadingDashboard, setLoadingDashboard] = useState(false);

  const [currentDateIndex, setCurrentDateIndex] = useState(0);
  const {
    settings: {
      nameKpi1,
      nameKpi2,
      nameKpi3,
      nameKpi4,
      nameWord,
      kpi4Available,
      hideChallengeWindow,
      hideAnualChart,
      vimeoVideo,
      hideRankings,
      hideProgressPoints
    }
  } = useSelector(state => state.utils);
  const firstLetter = nameWord ? ` ${nameWord[0].toLowerCase()}.` : '';
  const [currentDate, setCurrentDate] = useState(
    moment()
      .format('MMM')
      .replace('.', '')
      .toUpperCase()
  );
  const dispatch = useDispatch();

  const setRequest = () => {
    indexKpiUsersRequest({
      dispatch,
      params: {
        date_from: '',
        date_to: ''
      },
      successCallback: result => {
        setLoading(false);
        setKpiUsers(camelCaseRecursive(result.data.ranking));
        setMyRank(result.data.position);
      }
    });
  };

  const getUserPointsChart = () => {
    summaryKpiUsersRequest({
      dispatch,
      params: {
        date_to: new Date()
      },
      successCallback: result => {
        setkpiSummary(camelCaseRecursive(result.data));
        setCurrentDateIndex(result.data.length - 1);
        setLoadingChart(false);
      }
    });
  };

  const nextMonth = () => {
    const currentIndex = _.findIndex(kpiSummary, { month: currentDate });

    if (kpiSummary[currentIndex + 1]) {
      setCurrentDate(kpiSummary[currentIndex + 1].month);
      setCurrentDateIndex(currentIndex + 1);
    } else {
      setCurrentDate(kpiSummary[0].month);
      setCurrentDateIndex(0);
    }
  };

  const prevMonth = () => {
    const currentIndex = _.findIndex(kpiSummary, { month: currentDate });

    if (kpiSummary[currentIndex - 1]) {
      setCurrentDate(kpiSummary[currentIndex - 1].month);
      setCurrentDateIndex(currentIndex - 1);
    } else {
      setCurrentDate(kpiSummary[kpiSummary.length - 1].month);
      setCurrentDateIndex(kpiSummary.length - 1);
    }
  };

  const iframeVideoUrl = () => {
    const youtube = vimeoVideo?.split('/').includes('www.youtube.com');
    if (youtube) {
      return vimeoVideo?.replace('watch?v=', 'embed/');
    }
    const videoCode = vimeoVideo?.split('https://vimeo.com/')[1];
    return `https://player.vimeo.com/video/${videoCode}`;
  };

  useEffect(() => {
    const dispatchGetMe = () => {
      showMeRequest({
        dispatch,
        params: {
          date_from: '',
          date_to: ''
        },
        successCallback: response => {
          if (response.data.logged_in) {
            setUser(camelCaseRecursive(response.data.user_info));
          }
        }
      });
    };

    dispatchGetMe();
  }, [dispatch]);

  useEffect(() => {
    setLoadingDashboard(true);
    indexSlidesRequest({
      dispatch,
      params: {
        actives: true,
        sort_direction: 'asc',
        zone: 0
      },
      successCallback: result => {
        setSlides(camelCaseRecursive(result.data.data));
      }
    });
    indexSlidesRequest({
      dispatch,
      params: {
        actives: true,
        sort_direction: 'asc',
        zone: 8
      },
      successCallback: result => {
        setSlidesDashboard(camelCaseRecursive(result.data.data));
      }
    });
    indexSlidesRequest({
      dispatch,
      params: {
        actives: true,
        sort_direction: 'asc',
        zone: 11
      },
      successCallback: result => {
        setSlidesDashboard2(camelCaseRecursive(result.data.data));
        setLoadingDashboard(false);
      }
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    setRequest();
  }, [dispatch]);

  useEffect(() => {
    setLoadingChart(true);
    getUserPointsChart();
  }, [dispatch]);

  const avatarUrl = user.avatar.length ? user.avatar[0].url : '';
  const loader = () => <Spinner animation="border" variant="primary" />;
  const countRanking = 5;

  // const kpi1 = kpiSummary.length
  //   ? kpiSummary[currentDateIndex].kpi1Percent * 100
  //   : 0;
  // const kpi2 = kpiSummary.length
  //   ? kpiSummary[currentDateIndex].kpi2Percent * 100
  //   : 0;
  // const kpi3 = kpiSummary.length
  //   ? kpiSummary[currentDateIndex].kpi3Percent * 100
  //   : 0;
  // const kpi4 = kpiSummary.length
  //   ? kpiSummary[currentDateIndex].kpi4Percent * 100
  //   : 0;

  return (
    <>
      <Slides items={slides} />
      <Row className="mt-2">
        <Col md={12}>
          <h2 id="nn-dashboard-title" className="screen-title">
            Inicio
          </h2>
        </Col>
      </Row>
      <Row id="nn-dashboard-wrapper" className="mb-5 mt-2">
        <Col md={12} xl={7}>
          <Card className="card-primary-bg">
            <Card.Body style={{ minHeight: 178 }}>
              {loading ? (
                <Loader height="120px" minHeight="auto" />
              ) : (
                <Row style={{ marginTop: 8, textAlign: 'center' }}>
                  <Col md={2}>
                    <Image src={avatarUrl} className="avatar" roundedCircle />
                  </Col>
                  <Col
                    md={hideRankings === 1 ? 6 : 4}
                    className="mt-2"
                    style={{ alignSelf: 'center' }}
                  >
                    <div>
                      <h5
                        className="screen-title ml-3"
                        style={{ fontSize: 18 }}
                      >
                        ¡Hola {user.fullName}!
                      </h5>
                      <p>{user.zoneName}</p>
                    </div>
                  </Col>
                  <Col
                    md={hideRankings === 1 ? 3 : 6}
                    className="mt-4"
                    style={{ textAlign: 'center' }}
                  >
                    <Row>
                      {hideRankings === 1 ? null : (
                        <Col xs={6} style={{ minWidth: 79 }}>
                          <small>Ranking</small>
                          <h4 className="mt-3 screen-title">
                            {loading ? loader() : myRank}
                          </h4>
                        </Col>
                      )}
                      <div className="spacer-primary" />
                      <Col
                        xs={hideRankings === 1 ? 12 : 4}
                        style={{ minWidth: 120, textAlign: 'center' }}
                      >
                        <small>{nameWord} totales acumulados</small>
                        <h4 className="mt-3 screen-title">
                          {(user.balance + user.redeemedPoints).toFixed(1)}
                        </h4>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
            </Card.Body>
          </Card>
          {hideRankings === 1 ? null : (
            <Row className="mt-4 mb-4">
              <Col md={12}>
                {hideRankings !== undefined && (
                  <>
                    <span className="font-bold">
                      Ranking Top {countRanking}
                    </span>
                    <small className="font-bold">(Mes actual)</small>
                  </>
                )}
              </Col>
              <Col md={12}>
                <Table hover className="mt-3" size="sm">
                  <tbody className="table--tbody-ranking">
                    {kpiUsers.slice(0, countRanking).map(kpiUser => {
                      const avatarUser = kpiUser.user.avatar.length
                        ? kpiUser.user.avatar[0].url
                        : defaultImg;
                      return (
                        <tr key={kpiUser.userId}>
                          <td>{kpiUser.position}</td>
                          <td>
                            <Image
                              src={avatarUser}
                              className="table-avatar"
                              roundedCircle
                            />
                          </td>
                          <td>{kpiUser.user.fullName}</td>
                          <td>
                            <Badge className="badge-gray">
                              Total {kpiUser.totalPointsDecimal.toFixed(1)}
                              {firstLetter}
                            </Badge>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
              {hideRankings !== undefined && (
                <Col md={12}>
                  <LinkBtn
                    to="/ranking"
                    variant="outline-primary"
                    className="float-right"
                  >
                    Ver todo el Ranking
                  </LinkBtn>
                </Col>
              )}
            </Row>
          )}
          {slidesDashboard.length > 0 && (
            <Row className="mt-4">
              <Col md={12}>
                <Slides
                  items={slidesDashboard}
                  hideCaption
                  specific="slide-dashboard"
                />
              </Col>
            </Row>
          )}
        </Col>
        <Col md={12} xl={5}>
          <Row md={12}>
            <Col
              md={12}
              className={hideRankings === 1 ? 'mt-4 mt-xl-0' : ''}
              style={{ marginBottom: 20 }}
            >
              <Card className={`card-secondary-bg ${base.boxColorMyPoints}`}>
                <Card.Body style={{ minHeight: 178 }}>
                  <Row style={{ minHeight: '100px' }}>
                    <Col md={6} style={{ alignSelf: 'center' }}>
                      <div>
                        <p
                          style={{
                            fontWeight: 400,
                            fontSize: 18,
                            margin: 0
                          }}
                        >
                          Total de {nameWord || 'Puntos'} disponibles para canje
                        </p>
                      </div>
                    </Col>
                    <Col md={6} style={{ alignSelf: 'center' }}>
                      <div>
                        <h2
                          className="mt-3 text-center"
                          style={{
                            fontWeight: 700,
                            fontSize: 28
                          }}
                        >
                          {user.balance && user.balance.toFixed(1)}
                        </h2>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{ textAlign: 'center' }}>
                      <LinkBtn
                        to="/points-summary"
                        variant="outline-primary"
                        style={{ width: '80%' }}
                      >
                        Ver mis {nameWord}
                      </LinkBtn>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          {vimeoVideo && (
            <Row className="mx-2 mt-4 mt-xl-0">
              <Col>
                <h4 className="mb-2">
                  ¡Dale play y entérate de las últimas novedades!
                </h4>
                <iframe
                  src={iframeVideoUrl()}
                  width="100%"
                  height="360"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                />
              </Col>
            </Row>
          )}
          {hideProgressPoints === 1 ? null : (
            <>
              {loadingDashboard ? (
                <div className="d-flex justify-content-center align-items-center mt-5">
                  {loader()}
                </div>
              ) : (
                <>
                  {slidesDashboard2.length > 0 ? null : (
                    <Card
                      style={{
                        padding: '20px 5px',
                        backgroundColor: 'rgba(0,0,0,0)'
                      }}
                    >
                      <h4
                        className={`mt-3 ${
                          hideAnualChart === 1 ? 'mb-n3' : 'mb-4'
                        } text-center`}
                      >
                        Mis progresos
                      </h4>
                      {hideAnualChart === 1 ? null : (
                        <>
                          <h5
                            className="screen-title"
                            style={{
                              textAlign: 'center',
                              fontWeight: 700,
                              fontSize: 22
                            }}
                          >
                            Rendimiento Anual
                          </h5>
                          <div style={{ height: '250px' }}>
                            {loadingChart ? (
                              <div
                                className="d-flex justify-content-center"
                                style={{ marginTop: 100 }}
                              >
                                {loader()}
                              </div>
                            ) : (
                              <RankingChart
                                data={kpiSummary}
                                kpiSummary={kpiSummary}
                                currentDate={currentDate}
                                nextMonth={nextMonth}
                                prevMonth={prevMonth}
                              />
                            )}
                          </div>
                        </>
                      )}
                      <Row className="mt-5 px-4">
                        <Col xs={6}>
                          <Card
                            className="bg-color"
                            border="primary"
                            style={{ width: '100%', height: 120 }}
                          >
                            <Card.Body>
                              <Row>
                                <Col md={12}>
                                  <h6 className="text-center">{nameKpi1}</h6>
                                </Col>
                                <Col md={12}>
                                  <h4 className="text-center">
                                    {user.kpis?.kpi1}
                                  </h4>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col xs={6}>
                          <Card
                            className="bg-color"
                            border="primary"
                            style={{ width: '100%', height: 120 }}
                          >
                            <Card.Body>
                              <Row>
                                <Col md={12}>
                                  <h6 className="text-center">{nameKpi2}</h6>
                                </Col>
                                <Col md={12}>
                                  <h4 className="text-center">
                                    {user.kpis?.kpi2}
                                  </h4>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col className="mt-3">
                          <Card
                            className="bg-color"
                            border="primary"
                            style={{ width: '100%', height: 120 }}
                          >
                            <Card.Body>
                              <Row>
                                <Col md={12}>
                                  <h6 className="text-center">{nameKpi3}</h6>
                                </Col>
                                <Col md={12}>
                                  <h4 className="text-center">
                                    {user.kpis?.kpi3}
                                  </h4>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </Col>
                        {kpi4Available > 0 && (
                          <Col className="mt-3">
                            <Card
                              className="bg-color"
                              border="primary"
                              style={{ width: '100%', height: 120 }}
                            >
                              <Card.Body>
                                <Row>
                                  <Col md={12}>
                                    <h6 className="text-center">{nameKpi4}</h6>
                                  </Col>
                                  <Col md={12}>
                                    <h4 className="text-center">
                                      {user.kpis?.kpi4}
                                    </h4>
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Card>
                          </Col>
                        )}
                        {hideChallengeWindow === 1 ? null : (
                          <Col md={kpi4Available > 0 && 12} className="mt-3">
                            <Card
                              className="bg-color"
                              border="primary"
                              style={{ width: '100%', height: 120 }}
                            >
                              <Card.Body>
                                <Row>
                                  <Col md={12}>
                                    <h6 className="text-center">Desafíos</h6>
                                  </Col>
                                  <Col md={12}>
                                    <h4 className="text-center">
                                      {kpiSummary.length &&
                                        kpiSummary[currentDateIndex].challenges}
                                      {firstLetter}
                                    </h4>
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Card>
                          </Col>
                        )}
                      </Row>
                    </Card>
                  )}
                </>
              )}
            </>
          )}

          {slidesDashboard2.length > 0 && (
            <Slides
              items={slidesDashboard2}
              hideCaption
              specific="slide-dashboard"
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default Home;
